<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"
      ><img class="logo-img" src="/assets/images/ODR_Ecosystem.png" alt="logo"
    /></a>
  </div>
</div>

<div class="div">
  <p class="text-center m-0">{{ "header.lan" | translate }}</p>
  <div class="btn-ctn">
    <button
      nbButton
      status="warning"
      size="small"
      (click)="useLanguage('en')"
      class="m-2"
    >
      en
    </button>
    <button
      nbButton
      status="primary"
      size="small"
      (click)="useLanguage('el')"
      class="m-2"
    >
      el
    </button>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="userName"
        [picture]="user?.picture"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
